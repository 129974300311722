import React, { useEffect, useState } from 'react';
import "../static/styles/card.css";
import CardItem from './CardItem';
import {Link} from 'react-router-dom';
import config from '../config'

const microservice = 'cards';
const path = 'cards'; // chemin specifique 

export default function Card() {
  const apiGtwUrl = config.apiGtwUrl;
  const [cardData, setCardData] = useState([]);

  useEffect(() => {
    fetch(`${apiGtwUrl}api/${microservice}/${path}`) //http://localhost:8080/api/cards/cards
      .then(response => response.json())
      .then(data => {
        setCardData(data.card);
        console.log(data.card);
      })
      .catch(error => {
        console.error('Error fetching card data:', error);
      });
  }, [apiGtwUrl]);

  return (
    <div className='article'>
      {cardData.map((data, index) => (
        <Link to={`/card/${data.id}`} key={index} className="card-link">
          <CardItem data={data} />
        </Link>
      ))}
    </div>
  );
  
}

