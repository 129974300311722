import React, { useState } from 'react';
import "../static/styles/search.css";
import { useNavigate } from "react-router-dom";
import config from '../config';

export default function SearchButton() {
  const apiGtwUrl = config.apiGtwUrl;
  const microservice = 'cards';
  const action = 'keywords';

  const [searchTerm, setSearchTerm] = useState('');
  const [searchExecuted, setSearchExecuted] = useState(false);
  const navigate = useNavigate();


  const handleSearch = () => {
    fetch(`${apiGtwUrl}api/${microservice}/${action}`, { //http://localhost:8080/api/cards/keywords
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ searchTerm })
    })
      .then(response => response.json())
      .then(results => {
        if (results.length > 0) {
          navigate('/searchData', { state: { searchResults: results } });
        }
        setSearchExecuted(true); // Set searchExecuted to true after the search is performed
      })
      .catch(error => {
        console.error(error);
      });
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setSearchTerm(inputValue);

    // Clear searchExecuted if searchTerm is modified
    if (searchExecuted) {
      setSearchExecuted(false);
    }

    // Perform the search only if the Enter key is pressed
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div className="search-container">
      <input
        className="search-input"
        type="text"
        value={searchTerm}
        onChange={handleInputChange}
        onKeyPress={handleInputChange} // Listen for Enter key press
        placeholder='Rechercher...'
      />
    </div>
  );
}
