import React from 'react';
import CardItem from './CardItem';
import '../static/styles/card.css';
import '../static/styles/search.css';
import Navbar from '../component/Navbar';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

export default function SearchData() {
  const location = useLocation();
  const searchResults = location.state && location.state.searchResults ? location.state.searchResults : [];
  return (
    <div>
      <Navbar />
        <div className='searchData'>
          {searchResults.map((data, index) => (
             <Link to={`/card/${data.id}`} key={index} className="card-link">
            <CardItem data={data} key={index} />
            </Link>
          ))}
        </div>
    </div>
  );
}
