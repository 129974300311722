import React, { useEffect } from 'react';

const TrackingComponent = () => {
  useEffect(() => {
    const trackVisit = async () => {
      try {
        const visitData = {
          url: window.location.href,
          timestamp: new Date().toISOString(),
          ipAddress: '', // L'adresse IP sera ajoutée ici
        };

        // Récupérer l'adresse IP via l'API ipify
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        visitData.ipAddress = data.ip;

        // Envoi des données de visite au backend
        const backendResponse = await fetch('http://localhost:5000/api/visits', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(visitData),
        });

        if (backendResponse.ok) {
          console.log('Données de visite envoyées avec succès au backend');
        } else {
          console.error('Erreur lors de l\'envoi des données de visite au backend');
        }
      } catch (error) {
        console.error('Erreur lors de l\'envoi des données de visite au backend', error);
      }
    };

    trackVisit();
  }, []);
};

export default TrackingComponent;
