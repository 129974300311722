import React from "react";
import Carousel from "./Carousel";
import Card from "./Card";
import Navbar from "./Navbar";
import '../static/styles/carousel.css'
import TrackingComponent from './TrackingComponent';
export default function Home() {
  return (
    <div>
      <TrackingComponent />
      <Navbar />
        <div className="carousel-container">
          <Carousel/>
        </div>
      < Card />
    </div>
  );
}
