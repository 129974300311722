import React from "react";
import { NavLink } from "react-router-dom";
import "../static/styles/navbar.css";
import SearchButton from './searchButton';

export default function Navbar() {
  return (
    <div className="navbar">
      <NavLink exact to="/">
        Spectacles
      </NavLink>
      <NavLink to="/trailers" className="trailers">
        Bandes annonces
      </NavLink>
      <SearchButton />
    </div>
  );
}
