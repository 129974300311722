import React, { useEffect, useState } from 'react';
import '../static/styles/cardDetails.css';
import { useParams } from 'react-router-dom';
import Navbar from '../component/Navbar';
import config from '../config';

export default function CardDetails() {
  const apiGtwUrl = config.apiGtwUrl;
  const apiGetByIdUrl = config.api_getById_url;
  const { id } = useParams();
  const [cardDetails, setCardDetails] = useState(null);
  const microservice = 'cards';
  const path = `card/${id}`

  useEffect(() => {
    fetch(`${apiGtwUrl}api/${microservice}/${path}`)  //http://localhost:8080/api/cards/card/${id}
      .then(response => response.json())
      .then(data => {
        setCardDetails(data.card);
        console.log(data.card);
      })
      .catch(error => {
        console.error('Error fetching card data:', error);
      });
  }, [id, apiGtwUrl]);

  if (!cardDetails) {
    return <p>Loading...</p>;
  }

  const { img, title, authors, comedians, content, producers, partners, pictures, movies, press, files } = cardDetails;
  

  return (
    <><>
      <Navbar />
      <div className='content-details'>
        <div className='cardDetails' id={id}>
          <div className='img-content'>     
            {img && <img src={img} alt={title} className="card-image-details" />}
          </div>
          <div className="card-content-details">
            <div className='flex'>
              <h4 className="card-title-details">{title}</h4>
            </div>
            {authors && <h5 className="card-author-details">Auteur: {authors}</h5>}
            {comedians && <h5 className="card-actors-details">Acteurs: {comedians}</h5>}
            {content && <p className="card-description-details">{content}</p>}
            {producers && <h5 className="card-author-details">Producteur: {producers}</h5>}
            {partners && <h5 className="card-author-details">Partenaires: {partners}</h5>}
            {movies.length > 0 && (
              <div className='box'>
                {movies.map((movie) => (
                  movie.movies_url && (
                    <>
                      {movie.movies_url && <h3>Videos</h3>}
                      <video controls="controls" key={movie.id}>
                        <source
                          type='video/mp4'
                          src={apiGetByIdUrl + movie.movies_url}
                          alt={movie.title} />
                      </video>
                    </>
                  )
                ))}
              </div>
            )}
            {pictures.length > 0 && (
              <div className='box'>
                {pictures.map((picture) => (
                  <div key={picture.id}>
                    {picture.picture_url && (
                      <>
                        {picture.title && <h3>Galerie</h3>}
                        <img
                          src={apiGetByIdUrl + picture.picture_url}
                          alt={picture.title}
                          className="card-image" />
                      </>
                    )}
                  </div>
                ))}
              </div>
            )}
            {press.length > 0 && press.some((article) => article.url) && (
              <>
                <h2>La Presse en Parle</h2>
                {press.map((article) => (
                  article.url && (
                    <div className='box' key={article.id}>
                      <h3>{article.media}</h3>
                      <p>{article.extrait}</p>
                      <a href={article.url}>Lire l'article</a>
                    </div>
                  )
                ))}
              </>
            )}
            {files.length > 0 && files.some((file) => file.url) && (
              <>
                <h2>PDF pièce de theâtre</h2>
                {files.map((file) => (
                  file.url && (
                    <div className='box' key={file.id}>
                      <h3>{files.name}</h3>
                      <a href={apiGetByIdUrl + file.url} download={file.name}>{file.name}</a>
                    </div>
                  )
                ))}
              </>
            )}
          </div>
        </div>
      </div></>
      </>
  );
}
