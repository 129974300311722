import CardDetails from './component/CardDetails';
import Home from './component/Home';
import { BrowserRouter, Routes, Route} from "react-router-dom";
import Trailers from './component/Trailers';
import SearchData from './component/searchData';


export default function App () {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/card/:id" element={<CardDetails />} />
        <Route path='/trailers' element={<Trailers />}/>
        <Route path='/searchData' element={<SearchData />} />
      </Routes>
    </BrowserRouter>
    );
};

