import React, { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import config from '../config';
import "../static/styles/carousel.css";

export default function MyCarousel () {
  const apiGtwUrl = config.apiGtwUrl;
  const microservice = 'cards';
  const path = 'carousel/images';
  const [images, setImages] = useState([]);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiGtwUrl}api/${microservice}/${path}`); //http://localhost:8080/api/cards/carousel/images
        const data = await response.json();
        setImages(data.images);
      } catch (error) {
        console.error("Une erreur s'est produite lors de la récupération des images :", error);
      }
    };

    fetchData();
  }, [apiGtwUrl]);

  return (
    <Carousel
      showThumbs={true}
      renderThumbs={() => {}}
      showStatus={false}
      showArrows={true}
      swipeable={true}
      emulateTouch={true}
      infiniteLoop={true}
      centerMode={true}
      centerSlidePercentage={33.333}
      selectedItem={1}
      className="carousel"
    >
      {images.map((img, idx) => (
        <div key={idx}>
          <img className="img" src={img.url} alt={img.title} />
          <p>{img.title}</p>
        </div>
      ))}
    </Carousel>
  );
};
