import React from "react";

export default function CardItem({data}) {
    const { id,img, title, authors, comedians, summary } = data;
  

    return (
      <div className="card" id={id}>
        <img src={img} alt={title} className="card-image" />
        <div className="card-content">
          <h4 className="card-title">{title}</h4>
          <h6 className="card-author">Auteur: {authors}</h6>
          <h6 className="card-actors">Acteurs: {comedians}</h6>
          <p className="card-description">{summary}</p>
        </div>
      </div>
    );
  }
  