import React from "react";
import Navbar from "./Navbar";
import '../static/styles/trailers.css';

export default function Trailers(){
    return(
        <div>
            <Navbar />
            <div className="content">
                <h3>Chaine IDProduction</h3>
                <iframe title="video1" width="370" height="208" src="https://www.youtube.com/embed/g9kgEsD65XU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
            </div>
            <div className="content">
                <h3>Chaine Contrescarpes</h3>
                <iframe title="video2" width="370" height="208" src="https://www.youtube.com/embed/hzRxWGIQH1U" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
            </div>
        </div>
    )
}